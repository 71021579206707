// src/services/api.js
import fetch from 'isomorphic-fetch';

const BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'http://localhost:8080/v1/marine/'  // Use localhost in production
  : 'http://94.130.57.79:8080/v1/marine/';  // Use IP address in development

  const TIDE_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'http://localhost:8000'
  : 'http://94.130.57.79:8000';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const fetchMarineData = async (latitude, longitude, retries = 3) => {
  for (let i = 0; i < retries; i++) {
    try {
      const url = `${BASE_URL}?latitude=${latitude}&longitude=${longitude}&hourly=wave_height,wave_period,wave_direction,swell_wave_height,swell_wave_period,swell_wave_direction,wind_wave_height,wind_wave_direction&forecast_days=9&past_days=1&cell_selection=sea&timezone=auto`;
      
      //console.log(`Attempt ${i + 1}: Fetching marine data from ${url}`);
      
      const response = await fetch(url);
      
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error('Network response was not ok');
      }
      
    } catch (error) {
      console.error(`Error fetching marine data (attempt ${i + 1}):`, error);
      if (i === retries - 1) throw error;
      await delay(1000); // Wait for 1 second before retrying
    }
  
  }
};

export const fetchTideData = async (latitude, longitude, times) => {
  const params = new URLSearchParams({ latitude, longitude, model: 'GOT5.5' });
  times.forEach(time => params.append('times', time.toFixed(4)));

  const url = `${TIDE_BASE_URL}/tide?${params.toString()}`;

 // console.log(`Fetching tide data from ${url}`);

  try {
    const response = await fetch(url);
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Network response was not ok');
    }
  } catch (error) {
    console.error('Error fetching tide data:', error);
    throw error;
  }
};

// Helper function to calculate MJD
export const calculateMJD = (date) => {
  const julianDate = (date / 86400000.0) + 2440587.5;
  return julianDate - 2400000.5;
};



