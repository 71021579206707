import React, { useState, useEffect } from 'react';
import SurfInfoTable from './SurfInfoTable';
import { calculateMJD } from '../services/api';

const SurfInfo = ({ latitude, longitude, spotName, onDataLoaded, hoverIndex, onCellClick, initialData, initialTideData }) => {
//  console.log('SurfInfo received initialTideData:', initialTideData);

  const [data, setData] = useState(null);
  const [tideData, setTideData] = useState(null);
  const [error, setError] = useState(null);
  const [showAllTooltips, setShowAllTooltips] = useState(false);

  const calculateWaveEnergy = (height, period) => {
    const periodSeconds = period * 3600;
    const energy = (0.5 * Math.pow(height, 2) * 9.81 * periodSeconds) / 1000;
    return Math.round(energy);
  };

  const groupDates = (labels) => {
    return labels.reduce((acc, label, index) => {
      const [date, time] = label.split(' ');
      if (!acc[date]) {
        acc[date] = { startIndex: index, endIndex: index, times: [] };
      }
      acc[date].endIndex = index;
      acc[date].times.push(time);
      return acc;
    }, {});
  };

  const isLastHourOfDay = (dateString) => {
    const date = new Date(dateString);
    return date.getHours() === 23;
  };

  useEffect(() => {
   // console.log('SurfInfo useEffect triggered with initialData:', initialData);
    if (initialData) {
      setData(initialData);
      
      if (initialTideData) {
        // Transform initialTideData to the expected format
        const transformedTideData = initialTideData.tides.map((height, index) => ({
          height: height / 100, // Convert to meters (assuming the original data is in centimeters)
          time: new Date((initialTideData.times[index] - 2400000.5) * 86400000) // Convert MJD to JavaScript Date
        }));
        setTideData(transformedTideData);
      } else {
        fetchTides(initialData.hourly.time);
      }

      if (onDataLoaded) {
        const surfInfoData = initialData.hourly.time.map((time, index) => ({
          date: new Date(time),
          energy: calculateWaveEnergy(initialData.hourly.wave_height[index], initialData.hourly.wave_period[index]),
          windChop: initialData.hourly.wind_wave_height[index],
          swell_wave_direction: initialData.hourly.swell_wave_direction[index],
          wind_wave_direction: initialData.hourly.wind_wave_direction[index],
          wave_direction: initialData.hourly.wave_direction[index]
        }));
        onDataLoaded(surfInfoData);
      }
    }
  }, [initialData, initialTideData, latitude, longitude]);

  const fetchTides = async (times) => {
    const mjdTimes = times.map(time => calculateMJD(new Date(time)));
    try {
      const response = await fetch(`/api/tide-data?lat=${latitude}&lon=${longitude}&times=${mjdTimes.join(',')}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const fetchedTideData = await response.json();
      console.log('Fetched tide data:', fetchedTideData);
      
      // Transform the tide data
      const transformedTideData = fetchedTideData.tides.map((height, index) => ({
        height: height / 100, // Convert to meters (assuming the original data is in centimeters)
        time: new Date((fetchedTideData.times[index] - 2400000.5) * 86400000) // Convert MJD to JavaScript Date
      }));
      
      setTideData(transformedTideData);
    } catch (error) {
      console.error('Error fetching tide data:', error);
      setError('Failed to fetch tide data');
    }
  };

  if (error) return <div className="text-red-500">Error: {error}</div>;
  if (!data) return <div className="text-zinc-500">Loading...</div>;

  const labels = data.hourly.time.map((time) => {
    const date = new Date(time);
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    return `${dayOfWeek}\u00A0\u00A0${month}\u00A0${day} ${hours}`;
  });

  const waveEnergies = data.hourly.wave_height.map((height, index) =>
    calculateWaveEnergy(height, data.hourly.wave_period[index])
  );

  return (
    <SurfInfoTable
      data={data}
      tideData={tideData}
      labels={labels}
      waveEnergies={waveEnergies}
      groupDates={groupDates}
      isLastHourOfDay={isLastHourOfDay}
      calculateWaveEnergy={calculateWaveEnergy}
      hoverIndex={hoverIndex}
      onCellClick={onCellClick}
      showAllTooltips={showAllTooltips}
      setShowAllTooltips={setShowAllTooltips}
    />
  );
};

export default SurfInfo;