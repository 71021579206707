import React, { useState, useEffect, useMemo, useRef } from 'react';
import Map, { Marker } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { TiLocationArrow } from "react-icons/ti";
import { FaLocationDot } from "react-icons/fa6";
import { MdOpenInFull } from "react-icons/md";
import { MdCloseFullscreen } from "react-icons/md";
import spotsData from '../data/data.json';
import spotsData4 from '../data/data4.json';

const MapComponent = ({ location, isDarkMode = false, onMapClick, surfData, hoverIndex, onSpotClick }) => {
  const [viewState, setViewState] = useState({
    latitude: 45,
    longitude: 0,
    zoom: 5,
  });
  const [marker, setMarker] = useState(null);
  const [visibleSpots, setVisibleSpots] = useState([]);
  const [isMapExtended, setIsMapExtended] = useState(false);
  const [mapHeight, setMapHeight] = useState(0);

  const mapContainerRef = useRef(null);

  const allSpotsData = useMemo(() => [...spotsData, ...spotsData4], []);

  const clampCoordinates = (lng, lat) => {
    const clampedLng = ((lng + 180) % 360 + 360) % 360 - 180;
    const clampedLat = Math.max(-90, Math.min(90, lat));
    return { longitude: clampedLng, latitude: clampedLat };
  };

  const handleSpotClick = (spot) => {
    if (onSpotClick) {
      onSpotClick(spot);
    }
  };

  useEffect(() => {
    if (location) {
      setViewState({
        longitude: location.longitude,
        latitude: location.latitude,
        zoom: viewState.zoom,
      });

      setMarker({
        longitude: location.longitude,
        latitude: location.latitude,
      });
    }
  }, [location]);

  const mapStyle = isDarkMode
    ? 'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json'
    : 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json';

  const handleMapClick = (event) => {
    const { lng, lat } = event.lngLat;
    const clampedCoords = clampCoordinates(lng, lat);
    setMarker(clampedCoords);
    if (onMapClick) onMapClick(clampedCoords.longitude, clampedCoords.latitude);
  };

  const Arrow = ({ angle, colorClass }) => (
    <span
      className={`inline-block transform ${colorClass}`}
      style={{ transform: `translateY(3px) translateX(0px) rotate(${angle - 45 - 180}deg) translate(-18px,18px)`, fontSize: 52 }}
    >
      <TiLocationArrow />
    </span>
  );

  const renderMarker = () => {
    if (!marker) return null;

    if (!surfData || surfData.length === 0 || hoverIndex === null) {
      return (
        <Marker longitude={marker.longitude} latitude={marker.latitude}>
          <FaLocationDot size={30} />
        </Marker>
      );
    }

    const currentData = surfData[hoverIndex];

    return (
      <>
        <Marker longitude={marker.longitude} latitude={marker.latitude}>
          <div className="w-2 h-2 bg-zinc-900/[.6] dark:bg-zinc-100/[.6] rounded-full"></div>
        </Marker>

        <Marker longitude={marker.longitude} latitude={marker.latitude}>
          <Arrow
            angle={currentData.wave_direction || 0}
            colorClass="text-amber-500/[.8] dark:text-amber-400/[.8]"
          />
        </Marker>

        {currentData.windChop !== 0 && (
          <Marker longitude={marker.longitude} latitude={marker.latitude}>
            <Arrow
              angle={currentData.wind_wave_direction || 0}
              colorClass="text-blue-600/[.8] dark:text-blue-300/[.85]"
            />
          </Marker>
        )}
      </>
    );
  };

  const filterSpots = (bounds, maxSpots = 58) => {
    return allSpotsData.filter(spot => {
      const lng = parseFloat(spot.longitude);
      const lat = parseFloat(spot.latitude);
      return lng >= bounds.west && lng <= bounds.east && lat >= bounds.south && lat <= bounds.north;
    }).slice(0, maxSpots);
  };

  useEffect(() => {
    if (viewState.latitude && viewState.longitude) {
      const zoomFactor = Math.max(0.15, 10 - viewState.zoom); // Invert zoom scale
      const bounds = {
        north: viewState.latitude + (zoomFactor * 2.3),
        south: viewState.latitude - (zoomFactor * 2.3),
        east: viewState.longitude + (zoomFactor * 2.3),
        west: viewState.longitude - (zoomFactor * 2.3),
      };
      setVisibleSpots(filterSpots(bounds));
    }
  }, [viewState]);

  useEffect(() => {
    const updateMapHeight = () => {
      if (mapContainerRef.current) {
        setMapHeight(mapContainerRef.current.offsetHeight);
      }
    };
  
    updateMapHeight(); // Initial update
  
    // Create a ResizeObserver
    const resizeObserver = new ResizeObserver(updateMapHeight);
  
    // Observe the map container
    if (mapContainerRef.current) {
      resizeObserver.observe(mapContainerRef.current);
    }
  
    // Clean up
    return () => {
      if (mapContainerRef.current) {
        resizeObserver.unobserve(mapContainerRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  const toggleMapExtension = () => {
    setIsMapExtended(!isMapExtended);
  };

  return (
    <div
      ref={mapContainerRef}
      className={`relative rounded-sm shadow-sm overflow-hidden flex-grow ${isMapExtended ? 'min-h-[62dvh]' : 'min-h-32'}`}
    >
      <Map
        mapLib={maplibregl}
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        onClick={handleMapClick}
        style={{ width: '100%', height: '100%' }}
        mapStyle={mapStyle}
      >
        {visibleSpots.map((spot) => (
          <Marker
            key={spot.id}
            longitude={parseFloat(spot.longitude)}
            latitude={parseFloat(spot.latitude)}
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              handleSpotClick({
                latitude: parseFloat(spot.latitude),
                longitude: parseFloat(spot.longitude),
                text: spot.text
              });
            }}
          >
            <div className="cursor-pointer text-zinc-500 dark:text-zinc-300 transform -translate-y-2">
              <FaLocationDot size={20} />
            </div>
          </Marker>
        ))}
        {renderMarker()}
        {location && mapHeight <= window.innerHeight * 0.64 && (
          <div className="absolute top-1 right-1 z-10">
            <button
              onClick={toggleMapExtension}
              className="bg-zinc-200 dark:bg-zinc-800 p-1.5 rounded-full shadow-md"
              title={isMapExtended ? "Shrink map" : "Extend map"}
            >
              {isMapExtended ? (
                <MdCloseFullscreen size={22} style={{ transform: 'rotate(-45deg)' }} />
              ) : (
                <MdOpenInFull size={22} style={{ transform: 'rotate(-45deg)' }} />
              )}
            </button>
          </div>
        )}
      </Map>
    </div>
  );
};

export default MapComponent;